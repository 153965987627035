import "./App.css";
import logo from "./logo.svg";

function App() {
  return (
    <div className="container">
      <body>
        <div className="brand">
          <img src={logo} alt="Ounic Logo" />
          <div className="address">
            <a href="mailto:ask@ounic.co">ask@ounic.co</a>
          </div>
        </div>
        <script src="" async defer></script>
      </body>
    </div>
  );
}

export default App;
